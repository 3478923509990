import { uploadFileService, adminService, incidentService, masterService, reportService, expertService } from '../services';

const apiModule = {
  namespaced: true,
  state: {
    uploadFileService: uploadFileService,
    adminService: adminService,
    incidentService: incidentService,
    masterService: masterService,
    reportService: reportService,
    expertService: expertService
  },
  actions: {
    async apiCall ({ commit }, data) {
      const result = await this.state.api[data.svName][data.fnName](data.param);
      return result;
    }
  }
};

export default apiModule;
