import AuthProvider from './auth_service';
import IncidentProvider from './incident_service';
import UploadfileProvider from './uploadfile_service';

import AdminProvider from './admin_service';
import MasterProvider from './master_service';
import ReportProvider from './report_service';
import ExpertProvider from './expert_service';

export const authService = new AuthProvider('auth');
export const incidentService = new IncidentProvider('incident');
export const uploadFileService = new UploadfileProvider('uploadfile');

export const adminService = new AdminProvider('admin');
export const masterService = new MasterProvider('master');
export const reportService = new ReportProvider('report');
export const expertService = new ExpertProvider('expert');
