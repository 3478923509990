<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar
              class="list-unstyled menu-categories" 
              tag="ul"
              :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }"
            >
              <li class="menu" v-if="showMenu">
                <router-link to="/incident" class="dropdown-toggle" @click="toggleMobileMenu">
                  <div class="">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                      <span>{{ $t('Incident') }}</span>
                  </div>
                </router-link>
              </li>

              <li class="menu">
                <router-link to="/map" class="dropdown-toggle" @click="toggleMobileMenu">
                  <div class="">
                    <font-awesome-icon :icon="['fas', 'location-dot']" />
                      <span>Map</span>
                  </div>
                </router-link>
              </li>

              <li class="menu" v-if="showMenu">
                <a
                    class="dropdown-toggle"
                    data-bs-toggle="collapse"
                    data-bs-target="#report"
                    aria-controls="report"
                    aria-expanded="false"
                >
                  <div class="">
                    <font-awesome-icon :icon="['fas', 'chart-pie']" />
                      <span>{{ $t('Report') }}</span>
                  </div>
                  <div>
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-right"
                      >
                          <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                  </div>
                </a>

                <ul id="report" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                  <li><a href="/report/incidentrpt" class="">Chart</a></li>
                </ul>
              </li>

              <li class="menu">
                <router-link to="/expertmap" class="dropdown-toggle" @click="toggleMobileMenu">
                  <div class="">
                    <font-awesome-icon :icon="['fas', 'graduation-cap']" />
                      <span>ผู้เชี่ยวชาญ</span>
                  </div>
                </router-link>
              </li>

              <li class="menu" v-if="showMenu">
                <router-link to="/adminmanagement" class="dropdown-toggle" @click="toggleMobileMenu">
                  <div class="">
                    <font-awesome-icon :icon="['fas', 'user-gear']" />
                      <span>Admin</span>
                  </div>
                </router-link>
              </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    const store = useStore();

    const showMenu = ref(false);

    onMounted(() => {
      const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
      if (selector) {
        const ul = selector.closest('ul.collapse');
        if (ul) {
          let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
          if (ele) {
            ele = ele[0];
            setTimeout(() => {
              ele.click();
            });
          }
        } else {
          selector.click();
        }
      }

      if (localStorage.getItem('member')) {
        showMenu.value = true;
      }
    });

    const toggleMobileMenu = () => {
      if (window.innerWidth < 991) {
        store.commit('toggleSideBar', !store.state.is_show_sidebar);
      }
    };
</script>

<style>
    #sidebar ul.menu-categories ul.submenu > li a.active {
        color: unset;
    }
</style>